.nav-item:hover{
  background: linear-gradient(80deg, rgba(217, 255, 3, 0.432), rgba(0, 119, 255, 0.329), rgba(255, 0, 0, 0.377));
}

.App
.nav-link{
  color: gray;
}

.App
.nav-link:focus{
  color: black;
}

.navbar .navbar-toggler{
  border: none;
}

.navbar .navbar-toggler:focus{
  box-shadow: none;
}

.tema{
  cursor: pointer;
  filter: drop-shadow(-1px 1px 2px black);
}

.tema1:hover{
  animation: tema2 0.8s infinite ease;
}

.tema2:hover{
  animation: tema1 0.8s infinite ease;
}

.nav-link.disabled{
  color: red !important;
}

@keyframes tema1{
  to{
    transform: translateX(10px);
  }
  from{
    transform: translateX(0px);
  }
}

@keyframes tema2{
  to{
    transform: translateX(-10px);
  }
  from{
    transform: translateX(0);
  }
}

.unidad:hover{
  background-color: #0A2E48;
  color: white;
}


.container-material{
  border-right: 1px solid black;
  width: 250px;
  height: auto;
  background: #0A2E48;
}

.titulo-material{
  border-bottom: 1px solid black;
  background: white;
}

.body-material{
  height: 100vh;
}

/*estilos registrar temas*/
.container-tema{
  border-right: 1px solid black;
  width: 300px;
  height: auto;
  background: #0A2E48;
}

.text-respu{
  width: 25%;
}

/*Estilos para los torneos*/
.activo{
  overflow: hidden;
}

.no-activo{
  overflow: hidden;
}

.linea{
  position: absolute;
  z-index: 10;
  display: block;
}

.activo .linea-top{
  animation:linea-top 3s ease-in-out infinite;
  width: 100%;
  height: 3px;
  top: 0;
  left: -100%;
  background: linear-gradient(90deg, transparent, rgba(255, 0, 0, 0.7), rgba(0, 119, 255, 0.7), rgba(217, 255, 3, 0.7), rgba(217, 255, 3, 0.7));
}

.activo .linea-right{
  animation:linea-right 3s ease-in-out infinite;
  animation-delay: 1.5s;
  width: 3px;
  height: 100%;
  right: 0;
  top: -100%;
  background: linear-gradient(180deg, transparent, rgba(255, 0, 0, 0.7), rgba(0, 119, 255, 0.7), rgba(217, 255, 3, 0.7), rgba(217, 255, 3, 0.7));
}

.activo .linea-bottom{
  animation:linea-bottom 3s ease-in-out infinite;
  animation-delay: 3s;
  width: 100%;
  height: 3px;
  bottom: 0;
  right: -100%;
  background: linear-gradient(-90deg, transparent, rgba(255, 0, 0, 0.7), rgba(0, 119, 255, 0.7), rgba(217, 255, 3, 0.7), rgba(217, 255, 3, 0.7));
}

.activo .linea-left{
  animation:linea-left 3s ease-in-out infinite;
  animation-delay: 4.5s;
  width: 3px;
  height: 100%;
  bottom: 100%;
  left: 0;
  background: linear-gradient(0deg, transparent, rgba(255, 0, 0, 0.7), rgba(0, 119, 255, 0.7), rgba(217, 255, 3, 0.7), rgba(217, 255, 3, 0.7));
}

/*no activo*/

.no-activo .linea-top{
  animation:linea-top-stop 2s ease-in-out forwards;
  width: 100%;
  height: 3px;
  top: 0;
  left: -100%;
  background: linear-gradient(90deg, red, red);
}

.no-activo .linea-right{
  animation:linea-right-stop 2s ease-in-out forwards;
  animation-delay: 2s;
  width: 3px;
  height: 100%;
  right: 0;
  top: -100%;
  background: linear-gradient(180deg, red, red);
}

.no-activo .linea-bottom{
  animation:linea-bottom-stop 2s ease-in-out forwards;
  animation-delay: 3.7s;
  width: 100%;
  height: 3px;
  bottom: 0;
  right: -100%;
  background: linear-gradient(-90deg, red, red);
}

.no-activo .linea-left{
  animation:linea-left-stop 2s ease-in-out forwards;
  animation-delay: 5.4s;
  width: 3px;
  height: 100%;
  bottom: 100%;
  left: 0;
  background: linear-gradient(0deg, red, red);
}

@keyframes linea-top {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

@keyframes linea-right {
  0% {
    top: -100%;
  }
  100% {
    top: 100%;
  }
}

@keyframes linea-bottom {
  0% {
    right: -100%;
  }
  100% {
    right: 100%;
  }
}

@keyframes linea-left {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 100%;
  }
}

@keyframes linea-top-stop {
  0% {
    left: -100%;
  }
  100% {
    left: 0%;
  }
}

@keyframes linea-right-stop {
  0% {
    top: -100%;
  }
  100% {
    top: 0%;
  }
}

@keyframes linea-bottom-stop {
  0% {
    right: -100%;
  }
  100% {
    right: 0%;
  }
}

@keyframes linea-left-stop {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0%;
  }
}

@media(max-width: 680px){
  /*estilos curso*/
  .container-curso{
    display: flex;
    flex-direction: column;
  }
  
  .container-tema{
    width: 100%;
  }

  .titulo-material{
    width: 100%;
    border-bottom: none;
  }

  .container-material{
    width: 100%;
    border-right: none;
  }

  .body-material{
    width: 100%;
    display: flex;
    height: auto;
  }

  .imagen-curso{
    display: none;
  }

  .body-material
  .navbar-nav 
  .dropdown-menu.show{
    position: relative !important;
    transform: translate3d(0, 0, 0) !important;
  }

  /*estilos registrar temas*/
  .input-respu{
    width: 70%;
  }

  .input-tema-eliminar{
    margin-top: 8px;
  }

  .tabla-tiempos{
    width: 100% !important;
    margin: 0 !important;
    margin-bottom: 10px !important;
  }
  
  .formulario-tablas{
    width: 100% !important;
  }
}