.pixel-canvas {
  width: 100%;
  height: 100%;
  display: block;
}

.pixel-card {
  height: 400px;
  width: 300px;
  position: relative;
  overflow: hidden;
  display: grid;
  place-items: center;
  aspect-ratio: 4 / 5;
  border-radius: 25px;
  isolation: isolate;
  transition: border-color 200ms cubic-bezier(0.5, 1, 0.89, 1);
  user-select: none;
}

.pixel-card::before {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  aspect-ratio: 1;
  opacity: 0;
  transition: opacity 800ms cubic-bezier(0.5, 1, 0.89, 1);
}

.pixel-card:hover::before,
.pixel-card:focus-within::before {
  opacity: 1;
}