.posicion-sesion{
  position: absolute;
  top: 0;
  min-height: 650px;
}

.sesion{
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: clamp(100px, 100%, 900px);
  height: 400px;
}

.sesion-i{
  display: flex;
  align-items: center;
  transition: all 600ms ease;
  position: absolute;
  top: 0;
  width: 55%;
  height: 100%;
}

.sesion-i
.img{
  transition: all 2s ease;
  width: 100%;
  height: 100%;
  margin: auto;
}

.img1{
  border-radius: 30px 0 0 30px;
}

.img2{
  border-radius: 0 30px 30px 0;
}

.sesion-l{
  cursor: pointer;
  text-decoration: none;
}

.izquierda{
  transform: translate(-50%);
  background: black;
  box-shadow: 3px 2px 8px black;
  border-radius: 30px 0 0 30px;
}

.derecha{
  transform: translate(50%);
  background: black;
  box-shadow: -3px 2px 8px black;
  border-radius: 0 30px 30px 0;
}

.redes{
  width: 40px;
  cursor: pointer;
  transition: all 400ms ease;
}

.redes:hover{
  transform: scale(1.05);
}

.App
.submit{
  background: #0A2E48;
  color: white;
}

.App
.submit:hover{
  background: #0A2E48;
  color: white;
}

.link{
  background: none;
  border: none;
  color: rgb(0, 0, 255);
}

.vista-password{
  font-size: 23px;
  margin-left: 5px;
  position: absolute;
  right: 30px;
  color: gray;
  cursor: pointer;
}

.vista-gif{
  width: 11%;
  position: absolute;
  right: 30px;
  cursor: pointer;
}

.logo-pantalla-media{
  display: none;
}

/*estilos para input primereact*/
.form-prime .p-inputtext{
 padding: 1px 0;
 padding-left: 8px;
}

.p-password > i{
 display: flex;
 align-items: center;
}

@media(max-width: 680px){
  .posicion-sesion{
    position: relative;
  }
  
  .sesion{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .sesion-i{
    display: none;
  }

  .form-login-inativo{
    transition: all 400ms ease;
    transform: scale(0);
    display: none;
  }

  .form-registrar-inativo{
    transition: all 400ms ease;
    transform: scale(0);
    display: none;
  }

  .logo-pantalla-media{
    display: block;
  }
}

