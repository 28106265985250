/* Estilos a la barra scroll */
html::-webkit-scrollbar{
  width: 8px;
  height: 8px;
}

html::-webkit-scrollbar-corner{
  background: red;
}

html::-webkit-scrollbar-thumb{
  background: linear-gradient(80deg, rgb(47, 157, 247), white);
}

html::-webkit-scrollbar-thumb:hover{
  border-radius: 4px;
  background: linear-gradient(80deg, rgb(47, 157, 247), white, red);
}

.App {
  position: relative;
  text-align: center;
  height: 100vh;
}

.item-principal:hover{
  background: none !important;
}

.App .nav-link:focus{
  background: rgb(47, 157, 247);
  border-radius: 10px;
  transition: all 400ms ease;
  color: white !important;
}

.item-principal:hover .link-principal{
  color: white !important;
}

.item-principal:hover .link-ayuda{
  color: black !important;
}

.nombre-logo{
  opacity: 0;
  visibility: hidden;
}

.logo-navbar:hover .nombre-logo{
  transition: all 2s ease;
  opacity: 1;
  visibility: visible;
}

.logo-navbar:not(:hover) .nombre-logo{
  transition: all 2s ease;
}

.bg {
  animation:slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #abc7da 50%, rgb(219, 231, 240) 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
}

.bg2 {
  animation-direction:alternate-reverse;
  animation-duration:4s;
}

.bg3 {
  animation-duration:5s;
}

@keyframes slide {
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
}

.bgColombia {
  background: linear-gradient(80deg, rgba(217, 255, 3, 0.5), rgba(0, 119, 255, 0.3), rgba(255, 0, 0, 0.5));
  bottom:0;
  left:-50%;
  position:fixed;
  right:-50%;
  top:0;
  z-index: -1;
}

.bg {
  animation:slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #abc7da 50%, rgb(219, 231, 240) 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-2;
}

.bg2 {
  animation-direction:alternate-reverse;
  animation-duration:4s;
}

.bg3 {
  animation-duration:5s;
}

.circles{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li{
  position: absolute;
  display: block;
  list-style: none;
  width: 30px;
  height: 30px;
  animation: animate 25s linear infinite;
  bottom: -150px;
  
}

.circles li:nth-child(1){
  left: 98%;
  width: 30px;
  height: 30px;
  animation-delay: 0s;
}


.circles li:nth-child(2){
  left: 95%;
  width: 30px;
  height: 30px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3){
  left: 91%;
  width: 30px;
  height: 30px;
  animation-delay: 4s;
}

.circles li:nth-child(4){
  left: 80%;
  width: 30px;
  height: 30px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5){
  left: 85%;
  width: 30px;
  height: 30px;
  animation-delay: 0s;
}

.circles li:nth-child(6){
  left: 83%;
  width: 30px;
  height: 30px;
  animation-delay: 3s;
}

.circles li:nth-child(7){
  left: 88%;
  width: 30px;
  height: 30px;
  animation-delay: 7s;
}

.circles li:nth-child(8){
  left: 91%;
  width: 30px;
  height: 30px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9){
  left: 70%;
  width: 30px;
  height: 30px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10){
  left: 75%;
  width: 30px;
  height: 30px;
  animation-delay: 0s;
  animation-duration: 11s;
}

.circles li:nth-child(20){
  left: 10%;
  width: 30px;
  height: 30px;
  animation-delay: 11s;
}


.circles li:nth-child(19){
  left: 5%;
  width: 30px;
  height: 30px;
  animation-delay: 3s;
  animation-duration: 23s;
}

.circles li:nth-child(18){
  left: 8%;
  width: 30px;
  height: 30px;
  animation-delay: 15s;
}

.circles li:nth-child(17){
  left: 15%;
  width: 30px;
  height: 30px;
  animation-delay: 1s;
  animation-duration: 29s;
}

.circles li:nth-child(16){
  left: 18%;
  width: 30px;
  height: 30px;
  animation-delay: 11s;
}

.circles li:nth-child(15){
  left: 20%;
  width: 30px;
  height: 30px;
  animation-delay: 14s;
}

.circles li:nth-child(14){
  left: 13%;
  width: 30px;
  height: 30px;
  animation-delay: 18s;
}

.circles li:nth-child(13){
  left: 6%;
  width: 30px;
  height: 30px;
  animation-delay: 16s;
  animation-duration: 56s;
}

.circles li:nth-child(12){
  left: 2%;
  width: 30px;
  height: 30px;
  animation-delay: 3s;
  animation-duration: 46s;
}

.circles li:nth-child(11){
  left: 17%;
  width: 30px;
  height: 30px;
  animation-delay: 1s;
  animation-duration: 22s;
}

.flex-ayuda{
  height: 100%;
}

.menu-ayuda{
  height: 100%;
}


@keyframes animate {

  0%{
      transform: translateY(-1000px) rotate(750deg);
      opacity: 0;
      border-radius: 0%;
      background: rgb(255, 230, 0);
    } 
  50%{
      transform: translateY(-500px) rotate(500deg);
      opacity: 1;
      border-radius: 50%;
      background: rgb(0, 204, 255);
    }
  100%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 50%;
      background: rgb(255, 0, 0);
    }
}

@media(max-width: 1307px){
  .column-ayuda-reverse{
    display: flex;
    flex-direction: column-reverse;
  }
}

/* estilos primereact*/
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button{
  background-color: white;
}

svg{
  color: rgb(0, 183, 255);
}

.p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next:hover{
  background: transparent;
}

.p-dropdown-panel .p-dropdown-items{
  padding: 0;
  margin-bottom: 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item{
  border-bottom: 1px solid rgb(172, 172, 172);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:hover{
  transition: all 400ms ease;
  background: linear-gradient(80deg, rgba(217, 255, 3, 0.5), rgba(0, 119, 255, 0.3), rgba(255, 0, 0, 0.5));
}

.pdf-max{
  height: 555px;
}

.p-dialog-maximized .pdf-max{
  height: 85vh;
}

.p-accordion .p-accordion-header .p-accordion-header-link{
  color: gray;
  text-decoration: none;
}

/* estilos para un marquee */
.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}

.marquee span {
  transition: all 2s ease;
  display: inline-block;
  padding-left: 100%;
  animation: marquee 25s linear infinite;
}

@keyframes marquee {
  from { transform: translateX(0); }
  to { transform: translateX(-100%); }
}

.inicio{
  width: 45%;
}

.inicio h4{
  font-size: 35px !important;
}

.inicio p{
  font-size: 18px !important;
}

.wave-button-container {
  position: fixed; /* Posiciona el contenedor en la esquina */
  bottom: 20px;   /* Ajusta según sea necesario */
  right: 20px;    /* Ajusta según sea necesario */
  display: inline-block; /* Asegúrate de que el contenedor tenga el tamaño del botón */
}

.wave-buttonResultados {
  position: relative; /* Asegúrate de que el botón esté posicionado para el efecto */
  padding: 18px 30px;
  font-size: 16px;
  color: white;
  background-color: #ffc400;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden; /* Necesario para que el efecto de ola se mantenga dentro del botón */
}

.wave-buttonResultados::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  animation: wave 1.5s ease-out infinite;
}

.wave-buttonCalendar {
  position: relative; /* Asegúrate de que el botón esté posicionado para el efecto */
  padding: 18px 30px;
  font-size: 16px;
  color: white;
  background-color: #ff6d6d;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden; /* Necesario para que el efecto de ola se mantenga dentro del botón */
}

.wave-buttonCalendar::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  animation: wave 1.5s ease-out infinite;
}

.wave-button {
  position: relative; /* Asegúrate de que el botón esté posicionado para el efecto */
  padding: 18px 30px;
  font-size: 16px;
  color: white;
  background-color: #00d660;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden; /* Necesario para que el efecto de ola se mantenga dentro del botón */
}

.wave-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  animation: wave 1.5s ease-out infinite;
}

@keyframes wave {
  0% {
      transform: translate(-50%, -50%) scale(0);
      opacity: 1;
  }
  50% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0;
  }
  100% {
      transform: translate(-50%, -50%) scale(0);
      opacity: 1;
  }
}

.card-publicidad{
  width: 19em;
}

.card-torneos{
  width: 19em;
}

.trail {
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: rgba(71, 249, 255, 0.7);
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  animation: fadeOut 0.5s ease;
  z-index: 999999;
}

/* Animación para hacer que el rastro se desvanezca */
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(1.5);
  }
}

.redes-sociales{
  position: absolute;
  top: 0;
  justify-content: end;
  font-size: 28px;
}

.p-tabview .p-tabview-nav{
  margin-bottom: 0;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link{
  color: black;
  border-color: black;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar{
  background-color: black;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link, .p-accordion .p-accordion-header .p-accordion-header-link{
  color: gray;
  text-decoration: none;
}

.p-tabview .p-tabview-panels{
  padding: 0;
}

.chat{
  max-width: 400px;
  z-index: 9999 !important;
  right: 0 !important;
}

/* Movil */
@media(max-width: 840px){
  .container-testimonio h1{
    font-size: 35px !important;
  }

  .container-caracteristicas h1{
    font-size: 35px !important;
  }

  .container-paquetes h1{
    font-size: 35 !important;
  }

  .testimonio{
    width: 90% !important;
  }
}

@media(max-width: 650px){
  .card-torneos{
    width: 16em;
  }

  .flex-ayuda{
    flex-direction: column;
    height: auto;
  }

  .menu-ayuda{
    width: 100% !important;
    height: auto;
  }
}

@media(max-width: 500px){
  .card-torneos{
    width: 19em;
  }
}

@media(max-width: 499px){
  .chat{
    right: 0 !important;
  }
}